<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-card>
          <b-row>
            <b-col md="4">
              <p class="mt-2">
                Support email address:
              </p>
            </b-col>
            <b-col md="4">
              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="supportForm.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="mail@beontrips.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <p class="mt-2">
                Support Number:
              </p>
            </b-col>
            <b-col md="4">
              <b-form-group label="Phone">
                <validation-provider
                  #default="{ errors }"
                  name="Number"
                  rules="required|numeric|max:13"
                >
                  <b-form-input
                    v-model="supportForm.phone_number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="+18239857"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <Loader v-if="contentLoading" />

            <b-col md="12" class="demo-inline-spacing">
              <b-button
                variant="primary"
                type="submit"
                :disabled="loading"
                @click="updateSupportSettings"
              >
                Submit
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moduleService from "@/services/module/module.service";
import { resConditionCheck } from "@/helpers";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required } from "@core/utils/validations/validations";
import Loader from "@/layouts/skeloton-loader/Loader.vue";

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BButton,
    Loader
  },
  data() {
    return {
      contentLoading: true,
      items: [],
      supportForm: {
        email: "",
        phone_number: ""
      },
      contactId: '',
      required,
      loading: false
    };
  },

  mounted() {
    this.getSupportSettings();
  },
  methods: {
    async getSupportSettings() {
      this.contentLoading = true
      try {
        const res = await moduleService.getSupportSetting();
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false;

          this.items = res.data.data;
          this.contactId = this.items.id;
          this.supportForm.email = this.items.email;
          this.supportForm.phone_number = this.items.phoneNumber;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.contentLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async updateSupportSettings() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true;
          moduleService
            .editSupportSettings(this.contactId, this.supportForm)
            .then(res => {
              if (resConditionCheck(res.status) && res.data.data) {
                setTimeout(() => {
                  this.loading = false;
                }, 3000);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              setTimeout(() => {
                this.loading = false;
              }, 3000);
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    }
  }
};
</script>
